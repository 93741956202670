import React from 'react';
import useLoadTrips from '../hooks/useLoadTrips';
import TripsListHeader from './header';
import CircularLoaders from '../../../../../components/loaders';
import Trip from '../trip';
import { Paginate } from 'sw-ui';

const Trips = ({ companyId, accountId }) => {
  const {
    data: {
      tripsData,
      loading,
      error,
    },
    config: { page, itemsOnPage },
    handleChangePage,
  } = useLoadTrips(companyId);

  if (loading) {
    return <CircularLoaders />;
  }

  if (error) {
    return (
      <h2>
        Ошибка
      </h2>
    );
  }

  const { trips, tripsCount } = tripsData;

  return (
    <div>
      <TripsListHeader />
      { trips.map((trip) => (
        <Trip key={ trip.id } item={ trip } companyId={ companyId } accountId={ accountId } />
      )) }
      <Paginate
        currentPage={ page }
        itemsOnPage={ itemsOnPage }
        totalItems={ tripsCount }
        handlePrevious={ handleChangePage }
        handlePage={ handleChangePage }
        handleNext={ handleChangePage }
      />
    </div>
  );
};

export default Trips;
