import React from 'react';
import { createRoot } from 'react-dom/client';
import TopTabs from './TopTabs';
import BottomTabs from './BottomTabs';
import VacationAccountContext from '../context';
import styles from './styles/account.module.scss';

const Components = () => (
  <div className={ styles.wrap }>
    <TopTabs />
    <BottomTabs />
  </div>
);

const renderComponents = (box, { context }) => {
  const root = createRoot(box);

  const appService = context.getService('App');
  const userService = context.getService('User');
  const accountService = context.getService('Account');
  const companyService = context.getService('Company');
  const tripService = context.getService('Trip');
  const notificationsService = context.getService('Notifications');
  const hotelsService = context.getService('Hotels');
  const featureFlagsService = context.getService('FeatureFlags');
  const staticsService = context.getService('Statics');
  const employeeService = context.getService('Employee');
  const documentsConstructorService = context.getService('DocumentsConstructor');
  const airService = context.getService('Air');

  const appParamsRaw = appService.get().params;
  const appParams = {
    ...appParamsRaw,
    companyId: Number(appParamsRaw.companyId),
    accountId: Number(appParamsRaw.accountId),
  };

  const moveTo = ({
    mainTabId, tabId, query, companyId, accountId,
  }) => {
    const mainTab = mainTabId || appParams.mainTabId;
    const tab = tabId || appParams.tabId;
    const queryString = query ? `?${query}` : '';
    const company = companyId || appParams.companyId;
    const account = accountId || appParams.accountId;

    const path = `/vac/${account}/company/${company}/${mainTab}/${tab}${queryString}`;
    context.navigate(path);
  };

  const moveToNewTrip = (companyId) => {
    const { accountId } = this.context.getService('App').get().params;
    const path = `/account/${accountId}/company/${companyId}/trip/new`;

    this.context.navigate(path);
  };

  const moveToUserCartsAndNote = (user) => {
    const { companyId, accountId } = this.context.getService('App').get().params;

    const path = `/account/${accountId}/company/${companyId}/user/${user.Id}`;

    this.context.navigate(path);
  };

  root.render(
    <VacationAccountContext.Provider
      value={ {
        appParams,
        userService,
        accountService,
        companyService,
        tripService,
        notificationsService,
        hotelsService,
        featureFlagsService,
        employeeService,
        documentsConstructorService,
        staticsService,
        airService,
        moveTo,
        moveToNewTrip,
        moveToUserCartsAndNote,
      } }
    >
      <Components />
    </VacationAccountContext.Provider>,
  );

  return root;
};

export default renderComponents;
