const NAME = 'sw-vacation-trip';

function renderComponents() {
  return null;
}

class VacationTripModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    this.page = renderComponents(this.box, { context });
  }
}

export default function (core) {
  core.addModule(NAME, VacationTripModule);
}
