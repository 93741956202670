import { useCallback, useState } from 'react';

import useLoadData from '../../../hooks/useLoadData';
import Api from '../../../../../bi/api';

const useLoadTrips = (companyId) => {
  const itemsOnPage = 20;
  const [page, setPage] = useState(1);

  const loadTrips = useCallback(
    () => Api.Vacation.getTrips(companyId, { page, limit: itemsOnPage }), [page, companyId],
  );

  const {
    loading,
    error,
    data: tripsData,
  } = useLoadData(loadTrips, [loadTrips]);

  function handleChangePage(_, value) {
    setPage(value);
  }

  return {
    data: {
      loading,
      error,
      tripsData,
    },
    config: {
      page,
      itemsOnPage,
    },
    handleChangePage,
  };
};

export default useLoadTrips;
