import React from 'react';
import PropTypes from 'prop-types';

import { dateWithoutCurrentYear } from '../../../../bi/utils/formatDate';

import LinkToNewTab from '../../../../components/LinkToNewTab';

import { SERVICETYPE, SERVICETYPEICON } from '../../../../bi/constants/serviceType';

import MICE_IMG from '../../../../app/styles/img/calendar_mice.svg';
import IMG_BUS from '../../../../app/styles/img/bus_icon.png';

import styles from '../styles/trip.module.css';
import { STATUS_RU } from '../../../../constants/trip';

const Trip = ({
  item, companyId, accountId,
}) => {
  function getServiceIcon() {
    const service = item.service;

    switch (service) {
      case SERVICETYPE.AEROEXPRESS:
        return <div className='smartway-aeroexpress-temp' />;
      case SERVICETYPE.VIP_HALL:
        return <div className='smartway-vip-hall' />;
      case SERVICETYPE.AIR_ADDITIONAL_SERVICE:
        return <div className={ `airplane_ticket ${styles['icon-services']}` } />;
      case SERVICETYPE.EVENT:
        return <img className={ styles.mice } src={ MICE_IMG } alt='MICE' />;
      case SERVICETYPE.BUS:
        return <img className={ styles.bus } src={ IMG_BUS } alt='BUS' />;

      default:
        return (
          <i
            className={ `material-icons ${styles['icon-services']}` }
          >
            { SERVICETYPEICON[service] }
          </i>
        );
    }
  }

  const travelers = item.traveler.map(({ fullName, passengerId }) => (
    <div key={ passengerId }>
      { fullName }
    </div>
  ));

  return (
    <LinkToNewTab to={ `/vac/${accountId}/company/${companyId}/trip/${item.id}` }>
      <div className={ `${styles.row} ${styles.click}` }>
        <div className={ styles['col-1-8'] }>
          { item.id }
        </div>
        <div className={ styles['col-1-8'] }>
          { dateWithoutCurrentYear(item.checkInDate) }
        </div>
        <div className={ styles['col-1-8'] }>
          { dateWithoutCurrentYear(item.checkOutDate) }
        </div>
        <div className={ styles['col-1-8'] }>
          { item.Name }
        </div>
        <div className={ `${styles['col-1-8']} ${styles['data-services']}` }>
          { getServiceIcon() }
        </div>
        <div className={ styles['col-1-8'] }>
          { travelers }
        </div>
        <div className={ styles['col-1-8'] }>
          { item.price }
        </div>
        <div className={ styles['col-1-8'] }>
          { STATUS_RU[item.status] }
        </div>
      </div>
    </LinkToNewTab>
  );
};

Trip.propTypes = {
  item: PropTypes.object,
  accountId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  isEmployeeTrip: PropTypes.bool,
};

Trip.defaultProps = {
  item: {},
  isEmployeeTrip: false,
};

export default Trip;
