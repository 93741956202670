import React from 'react';

import styles from './styles/table.module.css';

const EPointsTable = ({ data }) => {
  return (
    <table className={ styles.table }>
      <thead>
        <tr>
          <th>Дата</th>
          <th>Тип</th>
          <th>Операция</th>
          <th>Начисление, ₽</th>
          <th>Списание, ₽</th>
        </tr>
      </thead>
      <tbody>
        { data.map((row, index) => (
          <tr key={ index } className='border border-gray-300'>
            <td>{ row.date }</td>
            <td>{ row.source }</td>
            <td>{ row.name }</td>
            <td>{ row.cashback }</td>
            <td>{ row.discount }</td>
          </tr>
        )) }
      </tbody>
    </table>
  );
};

export default EPointsTable;
