import React from 'react';
import useEPoints from './hooks/useEPoints';

import styles from './styles/index.module.css';
import { DatePicker, Paginate } from 'sw-ui';
import EPointsTable from './table';
import CircularLoaders from '../../../../components/loaders';

const LABELS = {
  SEARCH_LABEL: 'Отображать операции',
  START_DATE: 'с',
  END_DATE: 'по',
  TOTAL_BALANCE_LABEL: 'Баланс баллов',
};

const Points = ({ companyId }) => {
  const {
    data: {
      error, loading, points,
    },
    config: {
      startDate, endDate, page,
    },
    handleChangeEndDate,
    handleChangeStartDate,
    handleChangePage,
  } = useEPoints(companyId);

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.header }>
        <div className={ styles.search_wrapper }>
          <p>
            { LABELS.SEARCH_LABEL }
          </p>
          <p className={ styles.item }>
            { LABELS.START_DATE }
          </p>
          <div className={ styles.item }>
            <DatePicker onChange={ handleChangeStartDate } value={ startDate } />
          </div>
          <p className={ styles.item }>
            { LABELS.END_DATE }
          </p>
          <div className={ styles.item }>
            <DatePicker onChange={ handleChangeEndDate } value={ endDate } />
          </div>
        </div>
        { renderBalance() }
      </div>

      <div className={ styles.table_wrapper }>
        { renderTable() }
      </div>
    </div>
  );

  function renderTable() {
    if (error) {
      return <h1>Ошибочка</h1>;
    }

    if (loading) {
      return <CircularLoaders />;
    }

    return (
      <>
        <EPointsTable data={ points.info } />
        <Paginate
          handlePrevious={ handleChangePage }
          handlePage={ handleChangePage }
          handleNext={ handleChangePage }
          currentPage={ page }
          itemsOnPage={ points.metadata.limit }
          totalItems={ points.metadata.totalCount }
        />
      </>
    );
  }

  function renderBalance() {
    return !!points?.amount && (
      <div className={ styles.total }>
        <p className={ styles.label }>
          { LABELS.TOTAL_BALANCE_LABEL }
        </p>
        <p className={ styles.amount }>
          { points.amount }
        </p>
      </div>
    );
  }
};

export default Points;
