import useLoadProfile from './useLoadProfile';
import useLoadEnums from './useLoadEnums';

const MOCK_DOCS = [
  {
    id: 999999,
    type: 'DomesticPassport',
    number: '1010101010',
    surname: 'Кеков',
    name: 'Кек',
    patronymic: 'Кекич',
    firstName: 'Kirill',
    lastName: 'Mylnikov',
    middleName: 'Igorevich',
  },
  {
    id: 9999,
    type: 'ForeignPassport',
    number: '181818188',
    surname: 'mkmkmk',
    name: 'mkmkmkm',
    patronymic: 'mkmkmkmk',
    firstName: 'mkmkmkm',
    lastName: 'mkmkmk',
    middleName: 'mkmkmkmk',
    dueDate: '2036-12-12T00:00:00',
  },
];

const MOCK_BONUSES = [
  {
    id: 659832,
    type: 'Train',
    code: 'FPK',
    number: '122823123',
    employeeId: 2048220,
  },
  {
    id: 659833,
    type: 'Air',
    code: '6N',
    number: '289192',
    employeeId: 2048220,
  },
  {
    id: 659834,
    type: 'Air',
    code: '9B',
    number: 'zxasd',
    employeeId: 2048220,
  },
];

const MOCK_PROFILE = {
  fullName: 'Мемчуган Отдыхающий Петрович',
  dateOfBirth: '1985-01-01T00:00:00',
  citizenship: 'RU',
  sex: 'Woman',
  email: 'email@smartway.today',
  phone: '+79999999999',
  documents: MOCK_DOCS,
  bonuses: MOCK_BONUSES,
};

const useUserProfilePage = (companyId) => {
  const {
    loading: pLoading, data, error: pError,
  } = useLoadProfile(companyId);
  const {
    loading: eLoading, enums, error: eError,
  } = useLoadEnums();

  return {
    loading: pLoading || eLoading, error: pError || eError, data: data || MOCK_PROFILE, enums,
  };
};

export default useUserProfilePage;
