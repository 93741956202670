import Api from '../../../../../bi/api';
import useLoadData from '../../../hooks/useLoadData';

const useLoadProfile = (companyId) => {
  const loadProfile = async () => Api.Vacation.getUserProfile(companyId);
  const {
    loading, data, error,
  } = useLoadData(loadProfile, [companyId]);

  return {
    loading, data, error,
  };
};

export default useLoadProfile;
