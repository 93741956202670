import React from 'react';
import useUserProfilePage from './hooks/useUserProfile';

import General from './General';
import Documents from './Documents';
import BonusCards from './BonusCards';
import CircularLoaders from '../../../../components/loaders';

import styles from './styles.module.css';

const UserProfile = ({ companyId }) => {
  const {
    data, enums, loading, error,
  } = useUserProfilePage(companyId);

  if (loading) {
    return (
      <div>
        <CircularLoaders />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <h1>Ошибочка</h1>
      </div>
    );
  }

  return (
    <div className={ styles.container }>
      <General profile={ data } citizenshipEnums={ enums.citizenship } />
      <Documents documents={ data.documents } />
      <BonusCards bonuses={ data.bonuses } bonusesEnum={ enums.bonus } />
    </div>
  );
};

export default UserProfile;
