import { useCallback, useState } from 'react';
import moment from 'moment';

import Api from '../../../../../bi/api';
import useLoadData from '../../../hooks/useLoadData';
import { DATE } from '../../../../../constants/time';

const useEPoints = (companyId) => {
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(moment('12-01-2024'));
  const [endDate, setEndDate] = useState(moment('04-01-2025'));

  const loadPoints = useCallback(() => Api.Vacation.getPointsTransactions(companyId,
    {
      offset: page,
      limit: 10,
      startDate: startDate.format(DATE),
      endDate: endDate.format(DATE),
    }),
  [
    page,
    startDate,
    endDate,
  ]);

  const {
    loading, error, data: points,
  } = useLoadData(loadPoints, [loadPoints]);

  function handleChangePage(_, value) {
    setPage(value);
  }

  function handleChangeStartDate({ value }) {
    setStartDate(value);
  }

  function handleChangeEndDate({ value }) {
    setEndDate(value);
  }

  return {
    data: {
      loading,
      error,
      points,
    },
    config: {
      page,
      startDate,
      endDate,
    },
    handleChangePage,
    handleChangeStartDate,
    handleChangeEndDate,
  };
};

export default useEPoints;
