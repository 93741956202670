import React, { useState } from 'react';
import PropTypes from 'prop-types';

import decline from '../../../bi/utils/decline';
import { ACCOUNTTEXT, ACCOUNTSLIST } from '../../../bi/constants/account';

import LinkToNewTab from '../../../components/LinkToNewTab';

import styles from './styles/accounts.module.scss';

const COUNTNOTHIDDENCOMPANIES = 4;

const AccountItem = ({ item }) => {
  const [toggleCompanies, setToggleCompanies] = useState(false);

  const toggleOtherCompanies = () => setToggleCompanies((prev) => !prev);

  const renderToggleAllView = (companiesValue) => {
    const text = !toggleCompanies
      ? `Ещё ${companiesValue} ${decline(companiesValue, ACCOUNTSLIST)} в аккаунте`
      : ACCOUNTTEXT.HIDECOMPANY;

    return (
      <div className={ styles.others } onClick={ toggleOtherCompanies }>
        <div className={ styles.text }>{ text }</div>
        <span className={ `arrow ${styles.arrow} ${toggleCompanies ? styles.open : ''}` } />
      </div>
    );
  };

  const renderCompanies = () => {
    const { companies } = item;

    const list = companies.length <= COUNTNOTHIDDENCOMPANIES || toggleCompanies
      ? companies
      : companies.slice(0, COUNTNOTHIDDENCOMPANIES);

    return list.map(({
      accountId, companyId, companyName, shortCompanyName,
    }) => (
      <LinkToNewTab
        key={ `${companyId}` }
        to={ `/vac/${accountId}/company/${companyId}/account/user-profile` }
      >
        <div className={ styles.list }>
          { shortCompanyName || companyName } ({ companyId })
        </div>
      </LinkToNewTab>
    ));
  };

  const {
    companies, name, id,
  } = item;
  const companiesValue = companies.length > COUNTNOTHIDDENCOMPANIES ? companies.length - COUNTNOTHIDDENCOMPANIES : 0;
  const othersCompaniesHtml = companiesValue ? renderToggleAllView(companiesValue) : null;
  const companiesHtml = renderCompanies();
  const nameHtml = name || (
    <span className={ styles.nodicated }>
      { ACCOUNTTEXT.NOINDICATED }
    </span>
  );

  return (
    <div className={ styles.content }>
      <div className={ styles.header }>
        { nameHtml } ({ id })
      </div>
      <div className={ styles.details }>
        { companiesHtml }
      </div>
      { othersCompaniesHtml }
    </div>
  );
};

AccountItem.propTypes = {
  item: PropTypes.shape({
    companies: PropTypes.arrayOf(
      PropTypes.shape({
        accountId: PropTypes.number.isRequired,
        companyId: PropTypes.number.isRequired,
        companyName: PropTypes.string,
        shortCompanyName: PropTypes.string,
      }),
    ).isRequired,
    name: PropTypes.string,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default AccountItem;

