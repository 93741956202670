import React from 'react';
import styles from '../../styles/account.module.scss';

const TripsListHeader = () => {
  return (
    <div className={ styles.row }>
      <div className={ styles['col-1-8'] }>
        <label>#</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Дата начала</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Дата окончания</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Название</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Услуги</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Сотрудники</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Сумма</label>
      </div>
      <div className={ styles['col-1-8'] }>
        <label>Статус</label>
      </div>
    </div>
  );
};

export default TripsListHeader;
